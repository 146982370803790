<template>
  <div class="main_lci_wrapper ">
    <div class="main_layout d-flex">
      <div class="cards_and_logo">
        <LogoContainer></LogoContainer>
        <div class="cards_wrapper" v-for="(item, i) in cardItems" :key="i">
          <LciCard :item="item"></LciCard>
        </div>
        <div class="mt-5">
          <div
            class="mark_tooltip"
            role="button"
            v-tooltip.top-center="`${localization('Useful tips and support')}`"
          >
            <img src="@/assets/new-theme/icons/help-circle@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="tabs_wrapper">
        <div class="step_number_and_questions_number">
          <img
            role="button"
            src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
            alt=""
          />
          <span class="st_number_value">Step {{ this.active + 1 }} </span>
          <span class="st_questions_number">7 Sentences</span>
        </div>

        <!-- Start Questions -->
        <div class="tabs_steps mt-4">
          <span
            class="d-block step"
            :class="{ active: isActive(tab.index) }"
            v-for="tab in tabs"
            :key="tab.index"
          ></span>
        </div>
        <!-- Start Loop -->
        <div class="all_questions ">
          <div
            v-for="(tab, tabIndex) in tabs"
            :key="tabIndex"
            v-show="active === tab.index"
          >
            <div
              class="every_question"
              v-for="(question, i) in tab.questions"
              :key="question.id"
            >
              <div class="question_header">
                <div class="main_title">
                  <span>{{ question.number }}.</span>{{ question.title }}
                </div>
                <div class="main_icons">
                  <!-- //? start text to speech  -->
                  <audio controls id="audio" class="d-none">
                    <source id="audio-source" type="audio/mp3" />
                  </audio>
                  <div
                    @click="text_speech_audio(question.title)"
                    class="every_icon"
                    role="button"
                    v-tooltip.top-center="`${localization('Read out loud')}`"
                  >
                    <img src="@/assets/new-theme/icons/volume-max.png" alt="" />
                    <span>Listen</span>
                  </div>
                  <!-- @click="openEditLciDialog" -->
                  <!-- <div
                    role="button"
                    class="every_icon"
                    v-tooltip.top-center="
                      `${localization('Edit the question')}`
                    "
                    @click="openEditLciDialog(question)"
                  >
                    <span>Edit</span>
                    <img
                      src="@/assets/new-theme/icons/edit-05.png"
                      alt="edit icon"
                    />
                  </div> -->
                </div>
              </div>
              <!-- Answers -->
              <div class="mt-3">
                <template v-if="question.type === 0">
                  <el-radio-group
                    v-model="answers[question.question_id].answer_id"
                    class="main_radio_group"
                  >
                    <el-radio-button
                      v-for="(answer, i) in question.questions_options"
                      :key="i"
                      :label="i + 1"
                    >
                      {{ answer }}
                    </el-radio-button>
                  </el-radio-group>
                </template>
                <template v-else>
                  <el-input
                    type="textarea"
                    v-model="answers[question.question_id].answer_text"
                    rows="3"
                  ></el-input>
                </template>
              </div>
            </div>
            <!-- Start Buttons For Next And Previous -->
            <div class="mt-5 d-flex justify-content-center ">
              <button v-if="tabIndex > 0" @click="prevTab" class="prev_next">
                <img
                  src="@/assets/new-theme/icons/arrow-narrow-left-active-Button.png"
                  alt="edit icon"
                />
              </button>
              <button
                @click="nextTab"
                v-if="tabIndex !== tabs.length - 1"
                class="lci_btn mx-4"
              >
                Save and Continue
              </button>

              <button
                v-if="tabIndex < tabs.length - 1"
                @click="nextTab"
                class="prev_next"
              >
                <img
                  src="@/assets/new-theme/icons/arrow-narrow-right-Disable-Button.png"
                  alt="edit icon"
                />
              </button>

              <button
                v-if="tabIndex === tabs.length - 1"
                @click="submitAnswers"
                class="lci_btn mx-4"
              >
                Save and Continue
              </button>
            </div>
          </div>
        </div>
        <!-- End Questions -->

        <el-dialog :visible.sync="showEditDialog" top="4vh" width="40%">
          <div class="dialog_style">
            <div class="mb-5 img_radius">
              <div>
                <img
                  role="button"
                  src="@/assets/new-theme/icons/edit-05.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <h3 class="title mb-2">Edit an LCI question</h3>
              <p class="desc">
                If you wish to make a statement more accurate to you, insert
                your changes below.
              </p>
            </div>
            <div class="mt-4">
              <p class="desc2">
                The statement #01
              </p>
              <textarea
                class="text_area"
                v-model="questionModalData.title"
              ></textarea>
            </div>
            <div class="lci_modal_buttons mt-4">
              <button
                @click="closeEditLciDialog"
                class="lci_btn_modal_cancel mx-4"
              >
                Cancel
              </button>
              <button @click="closeEditLciDialog" class="lci_btn save mx-4">
                Confirm
              </button>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "LciQuestions",
  data: () => ({
    cardItems: [
      {
        src: require("@/assets/new-theme/icons/clock.png"),
        title: "Take the time you need",
        description:
          "While there are no right or wrong answers, there are answers that are more accurate to who you are than others."
      },
      {
        src: require("@/assets/new-theme/icons/check-circle-broken.png"),
        title: 'Avoid answering "Sometimes"',
        description:
          'Make every attempt to avoid answering "Sometimes" unless the answer truly is "Sometimes".'
      },
      {
        src: require("@/assets/new-theme/icons/target-01.png"),
        title: "Be as accurate and detailed as possible",
        description:
          "Try to be as accurate and detailed as possible in responding to the open-end questions at the end of the questionnaire."
      }
    ],
    testval: [],
    questionslist: [],
    arrOfIDs: [],
    modals: {
      basic: false,
      centered: false,
      custom: false,
      confirm: false
    },
    answers: [
      "Never Ever",
      "Almost Never",
      "Sometimes",
      "Almost Always",
      "Always"
    ],
    one: 0,
    result: [],
    editable: false,
    answersall: {},
    userLciCms: null,
    checkUserScore: false,
    checkUserScoreTwo: false,
    // NEW THEME
    step: 1,
    allQuestionsList: [],
    answers: [],
    tabs: [],
    active: 0,
    activeTabs: [0],
    currentTab: 0,
    showEditDialog: false,
    questionModalData: {}
  }),
  // created() {
  //   this.initializeTabs();
  //   this.initializeAnswers();
  // },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    }
  },

  mounted() {
    this.$store.dispatch("user/get_questions", { id: this.id }).then(_ => {
      this.questionslist = this.$store.state.user.questions;
      for (var i in this.questionslist) {
        this.questionslist[i].number = +i + 1;
        this.questionslist[i].title = this.questionslist[i].question_body
          ? this.questionslist[i].question_body
          : this.questionslist[i].title;
        console.log("i", i);
      }
      this.allQuestionsList = this.$store.state.user.questions;
      const chunkSize = 7;
      for (let i = 0; i < this.allQuestionsList.length; i += chunkSize) {
        const questionsChunk = this.allQuestionsList.slice(i, i + chunkSize);
        this.tabs.push({
          index: this.tabs.length,
          questions: questionsChunk
        });
      }
      console.log("this.tabs", this.tabs);
      // const lastQuestions = this.tabs
      //   .pop()
      //   .questions.concat(this.allQuestionsList.slice(-3));
      // this.tabs.push({
      //   index: this.tabs.length,
      //   questions: lastQuestions
      // });
      console.log(this.allQuestionsList);
      this.allQuestionsList.forEach(question => {
        this.$set(this.answers, question.question_id, {
          id: question.id,
          answer_id: question.answer_id || "",
          answer_text: question.answer_text || "",
          question_id: question.question_id,
          question_body: question.question_body
        });
      });
      console.log(this.allQuestionsList);
    });
  },
  methods: {
    openEditLciDialog(question) {
      this.showEditDialog = true;
      console.log(question);
      this.questionModalData = question;
    },
    closeEditLciDialog() {
      this.showEditDialog = false;
    },
    switchToNextTab() {
      console.log("tab 2");
    },
    isActive(index) {
      return this.activeTabs.includes(index);
    },
    nextTab() {
      if (this.active < this.tabs.length - 1) {
        this.active++;
      }
      this.activeTabs.push(this.active);
    },
    prevTab() {
      if (this.active > 0) {
        this.active--;
      }
      this.activeTabs.pop(this.active);
    },
    submitAnswers() {
      let mainAnswers = { answers: Object.values(this.answers) };
      // check if user filled all answers
      mainAnswers.answers.every(ques => {
        //  if no answer neither at anwer id nor at answer text properties

        if (ques.answer_id == null && ques.answer_text == null) {
          return false;
        }
        return true;
      });
      this.$store
        .dispatch("user/save_answers", {
          query: mainAnswers
        })
        .then(response => {});
      // console.log({ answers: Object.values(this.answers) });
      // Submit answers to the server
    },
    filter(ques) {
      this.arrOfIDs.push(ques.id);
    },
    save() {
      // check if user filled all answers
      let allQuestionsAnswered = this.questionslist.every(ques => {
        // debugger;
        //  if no answer neither at anwer id nor at answer text properties
        if (ques.answer_id == null && ques.answer_text == null) {
          return false;
        }
        return true;
      });

      if (allQuestionsAnswered) {
        for (let index = 0; index < this.questionslist.length; index++) {
          const element = this.questionslist[index];

          if (element.answer_text) {
            element.answer_id = null;
          } else if (element.answer_id) {
            element.answer_text = null;
          }
          this.result[index] = {
            id: element.id,
            answer_id: element.answer_id,
            question_id: element.question_id,
            question_body:
              this.arrOfIDs.indexOf(element.id) > -1 ? element.title : null,
            answer_text: element.answer_text
          };
        }
        // check if user answered all question with same answers
        // variables to check if value changed
        let valueChoiceChanged = false,
          valueTextChanged = false;
        // loop to check there are at least one differnet answer at both type of questions
        for (let i = 1; i < this.result.length; i++) {
          // current & previous answers of both type of questios
          let answerId = this.result[i].answer_id,
            answerTxt = this.result[i].answer_text,
            prevAnswerId = this.result[i - 1].answer_id,
            prevAnswerTxt = this.result[i - 1].answer_text;
          if (answerId === null) {
            // check for text questions
            // null coz when we move from multichoice to text questions
            if (prevAnswerTxt !== answerTxt && prevAnswerTxt !== null)
              // if one value changed
              valueTextChanged = true;
          } else {
            // check for Multichoice quesitons
            if (answerId !== prevAnswerId) valueChoiceChanged = true;
          }
        }
        // only execute if answers are unique
        if (valueChoiceChanged && valueTextChanged) {
          this.answersall = {
            answers: this.result,
            status: true
          };
          this.$store
            .dispatch("user/save_answers", {
              query: this.answersall
            })
            .then(response => {
              if (response.status == "done") {
                this.$router.push("/profile");
              }
            })
            .catch(err => {
              if (err.response.data.error == "LCI error message") {
                Swal.fire({
                  text: `${this.localization("LCI error message")}`,
                  type: "error",
                  icon: "error",
                  confirmButtonText: `${this.localization("Ok")}`,
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
              }
            });
        } else {
          Swal.fire({
            // text: "Kindly input uniques answers",
            text: `${this.localization("Unique answers")}`,
            icon: "error",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000
          });
        }
      } else {
        Swal.fire({
          // text: "Please answer all questions",
          text: `${this.localization("Answer all questions")}`,
          icon: "error",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000
        });
      }
    },

    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    },

    saveQuestion() {
      // check if user filled all answers
      let allQuestionsAnswered = this.questionslist.every(ques => {
        // debugger;
        //  if no answer neither at anwer id nor at answer text properties
        if (ques.answer_id == null && ques.answer_text == null) {
          return false;
        }
        return true;
      });

      // if (allQuestionsAnswered) {
      for (let index = 0; index < this.questionslist.length; index++) {
        const element = this.questionslist[index];

        if (element.answer_text) {
          element.answer_id = null;
        } else if (element.answer_id) {
          element.answer_text = null;
        }
        this.result[index] = {
          id: element.id,
          answer_id: element.answer_id,
          question_id: element.question_id,
          question_body:
            this.arrOfIDs.indexOf(element.id) > -1 ? element.title : null,
          answer_text: element.answer_text
        };
      }
      // check if user answered all question with same answers
      // variables to check if value changed
      let valueChoiceChanged = false,
        valueTextChanged = false;
      // loop to check there are at least one differnet answer at both type of questions
      for (let i = 1; i < this.result.length; i++) {
        // current & previous answers of both type of questios
        let answerId = this.result[i].answer_id,
          answerTxt = this.result[i].answer_text,
          prevAnswerId = this.result[i - 1].answer_id,
          prevAnswerTxt = this.result[i - 1].answer_text;
        if (answerId === null) {
          // check for text questions
          // null coz when we move from multichoice to text questions
          if (prevAnswerTxt !== answerTxt && prevAnswerTxt !== null)
            // if one value changed
            valueTextChanged = true;
        } else {
          // check for Multichoice quesitons
          if (answerId !== prevAnswerId) valueChoiceChanged = true;
        }
      }
      // only execute if answers are unique
      if (valueChoiceChanged) {
        this.answersall = {
          answers: this.result,
          status: false
        };
        this.$store
          .dispatch("user/save_answers", {
            query: this.answersall
          })
          .then(response => {});
      } else {
        Swal.fire({
          text: `${this.localization("Unique answers")}`,
          icon: "error",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000
        });
      }
      // } else {
      //   Swal.fire({
      //     title: "Error!",
      //     text: "Please answer all questions",
      //     icon: "error",
      //     showCancelButton: false,
      //     showConfirmButton: false,
      //     timer: 3000
      //   });
      // }
    }
    // switchToTrueForLci() {
    //   this.checkUserScore = !this.checkUserScore;
    //   window.scrollTo(0, 0);
    // }
  }
};
</script>

<style lang="scss" scoped>
.main_lci_wrapper {
  // background: #eee;
  width: 100%;
  .main_layout {
    gap: 40px;
  }
  .cards_and_logo {
    max-width: 25%;
    .cards_wrapper {
      margin-top: 40px;
    }
  }
  .tabs_wrapper {
    flex-grow: 1;
    // background: #eee;
    .step_number_and_questions_number {
      display: flex;
      align-items: center;
      gap: 10px;
      .st_number_value {
        color: #101828;
        font-size: 22px;
        font-weight: 500;
      }
      .st_questions_number {
        color: #667085;
        font-size: 14px;
      }
    }
    .tabs_steps {
      display: flex;
      justify-content: space-between;
      .step {
        height: 4px;
        border-radius: 8px;
        background-color: #e9d7fe;
        width: 180px;
        &.active {
          background-color: #7f56d9;
        }
      }
    }
    .all_questions {
      margin-top: 30px;
      .every_question {
        margin-bottom: 30px;
        .question_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .main_title {
            color: #344054;
            font-weight: 400;
            font-size: 17px;
            flex-grow: 1;
          }
          .main_icons {
            display: flex;
            gap: 30px;
            align-items: center;
            .every_icon {
              display: flex;
              gap: 7px;
              align-items: center;
              color: #7f56d9;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
.main_radio_group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  // flex-wrap: wrap;
}
::v-deep .el-radio-button {
  // border: 1px solid #d0d5dd;
  // width: 100%;
  min-width: 180px;
  max-width: 200px;
  border-radius: 8px !important;
  &.is-active {
    .el-radio-button__inner {
      background: #7f56d9;
      border-color: #7f56d9;
      border-radius: 8px !important;
    }
  }
}
::v-deep .el-radio-button__inner {
  width: 100%;
  border-radius: 8px !important;
  border: 0;
  border: 1px solid #d0d5dd;
  color: #344054;
  font-weight: 500;
}

// Buttons Next And Previous
.lci_btn {
  background-color: #f2f4f7;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
  &.save {
    background-color: #7f56d9;
    color: #fff;
  }
}
.prev_next {
  background-color: #f2f4f7;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  color: #667085;
  width: 70px;
}

.mark_tooltip {
  border: 1px solid #d0d5dd;
  width: fit-content;
  padding: 12px;
  border-radius: 50%;
}

.lci_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lci_btn_modal_cancel {
  background-color: #fff;
  color: #667085;
  border-radius: 8px;
  padding: 12px 20px;
  border: 1px solid #eaecf0;
  width: 240px;
}

.dialog_style {
  .title {
    color: #101828;
    font-weight: 500;
    font-size: 20px;
  }
  .desc {
    color: #667085;
    font-weight: 400;
    font-size: 14px;
  }
  .desc2 {
    color: #344054;
    font-weight: 500;
    font-size: 16px;
  }
  .text_area {
    border: 1px solid #d0d5dd;
    height: 92px;
    width: 100%;
    resize: none;
    border-radius: 8px;
    color: #101828;
    padding: 15px;
    outline: none;
    word-break: break-word;
  }

  .img_radius {
    background: #f9f5ff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
